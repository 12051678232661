/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const IntlAuthRedirect = ({ location }) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const code = urlSearchParams.get('code');
  const state = urlSearchParams.get('state');

  const url = `rockerapp://intl-auth?code=${code}&state=${state}`;

  useEffect(() => {
    window.location.replace(url);
  }, []);
  return <div>{JSON.stringify(url)}</div>;
};

export default IntlAuthRedirect;
